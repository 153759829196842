import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { api_constants } from '../../constants/api-constants';

@Component({
  selector: 'app-edit-notofication',
  templateUrl: './edit-notofication.component.html',
  styleUrls: ['./edit-notofication.component.scss'],
})
export class EditNotoficationComponent {
  private unsubscribe = new Subject<void>();
  edit_notification_form: any = FormGroup;
  button_loader: boolean = false;
  submited: boolean = false;
  message_id: string = '';
  constructor(
    public dialogRef: MatDialogRef<EditNotoficationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    private toast: ToastrService,
    private _form_builder: FormBuilder
  ) {
    this.message_id = data?._id;
    this.edit_notification_form = this._form_builder.group({
      title: ['', Validators.required],
      message: ['', Validators.required],
    });
    this.edit_notification_form.patchValue({
      title: data?.title,
      message: data?.message,
    });
  }

  ngOnInit(): void {}

  get f() {
    return this.edit_notification_form.controls;
  }

  closeModal(status: boolean) {
    this.dialogRef.close(status);
  }
  close() {
    this.dialogRef.close();
  }
  submit(data: any) {
    let $this = this;
    this.api
      .ExecutePut(
        `${this.api?.baseUrl + api_constants.edit_push_notification}/${
          this.message_id
        }`,
        data
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next(value) {
          $this.toast.success(`Message successfully Changed`, 'Success', {
            closeButton: true,
          });
        },
        error(err) {
          console.log('error:', err);
          $this.toast.error(`Message Change Failed,Try again later`, 'Error', {
            closeButton: true,
          });
        },
      });
    this.closeModal(true);
  }
}
