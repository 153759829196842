import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './shared/interceptor/jwt.interceptor';
import { TermsofserviceComponent } from './termsofservice/termsofservice.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { UserDeleteRequestComponent } from './user-delete-request/user-delete-request.component';
import { ReasonComponent } from './user-delete-request/components/reason/reason.component';
import { RequestSucessComponent } from './user-delete-request/components/request-sucess/request-sucess.component';
import { GoogleMapsModule } from '@angular/google-maps';


@NgModule({
  declarations: [
    AppComponent,
    TermsofserviceComponent,
    PrivacypolicyComponent,
    UserDeleteRequestComponent,
    ReasonComponent,
    RequestSucessComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({preventDuplicates:true}),
    SharedModule,
    HttpClientModule,
    GoogleMapsModule,

  ],
  providers: [{
    provide:HTTP_INTERCEPTORS,
    useClass:JwtInterceptor,
    multi:true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
