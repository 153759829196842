<div class="popup-div w-full justify-center flex-col items-center">
  <div class="flex justify-between items-center pb-3">
    <div class="flex gap-4 items-center">
      <div class="yellow-bar"></div>
      <h1 class="sub-text text-center">Edit Notification</h1>
    </div>
    <img
      src="../../../assets/Icons/close-icon.svg"
      class="close-icon"
      (click)="close()"
      style="cursor: pointer"
    />
  </div>
  <form
    class="w-full flex flex-col gap-4 mb-6"
    [formGroup]="edit_notification_form"
  >
    <div class="flex-column">
      <h2 class="sidebar-text">
        <span class="error-validation-text" style="font-size: 22px !important"
          >*</span
        >Title
      </h2>
    </div>
    <div class="inputForm">
      <input
        type="text"
        class="input"
        placeholder="Enter the message"
        name="title"
        style="width: 90% !important"
        formControlName="title"
        [ngClass]="{ 'form-input-error': f.title.errors && submited }"
      />
    </div>
    <div class="flex-column">
      <h2 class="sidebar-text">
        <span class="error-validation-text" style="font-size: 22px !important"
          >*</span
        >Message
      </h2>
    </div>
    <div class="inputForm">
      <input
        type="text"
        class="input"
        placeholder="Enter the message"
        name="message"
        style="width: 90% !important"
        formControlName="message"
        [ngClass]="{ 'form-input-error': f.message.errors && submited }"
      />
    </div>
  </form>
  <button
    class="button-submit flex justify-center items-center"
    (click)="submit(edit_notification_form?.value)"
  >
    <span *ngIf="!button_loader">Change</span>
    <mat-spinner
      class="button-loader"
      *ngIf="button_loader"
      diameter="20"
    ></mat-spinner>
  </button>
</div>
