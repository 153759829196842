<div class="popup-div w-full justify-center flex-col items-center">
  <div class="w-full flex justify-between items-center pb-4">
    <div class="w-full flex gap-4 items-center">
      <div class="yellow-bar"></div>
      <h1 class="sub-text text-center">Confirmation</h1>
    </div>
    <img class="close-img" (click)="closeModal(false)" src="../../../../assets/Icons/close.svg" />
  </div>
  <h4 class="sub-text" style="text-align: center">{{ data.message }}</h4>
  <div class="flex items-center justify-center gap-2 w-full mt-5">
    <button class="button-submit" (click)="onAction('yes')">Yes</button>
    <button class="button-submit" (click)="onAction('no')">No</button>
  </div>
</div>
