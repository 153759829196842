<div class="sidebar-main">
  <div class="logo-section flex w-full justify-around items-center">
    <h1 class="large-text">Bingo</h1>
    <button
      class="lg:hidden"
      (click)="menu_toggle()"
      style="width: 24px; height: 24px"
    >
      <img src="../../../../assets/Icons/close.svg" />
    </button>
  </div>
  <div class="flex flex-col gap-8 pt-16 max-xs:gap-12 max-xxs:px-6">
    <button class="sidebar-btn w-full flex items-center justify-center">
      <div
        class="sidebar-menu flex items-center gap-4 py-2 pl-4"
        routerLinkActive="router-link-active"
        [routerLink]="['Dashboard']"
        (click)="closeSidebar()"
      >
        <span class="sidebar-icon flex items-center justify-center">
          <img
            class="w-full h-full"
            src="../../../../assets/Icons/dashboard.svg"
          />
        </span>
        <span class="sidebar-text">Dashboard</span>
      </div>
    </button>
    <button class="sidebar-btn w-full flex items-center justify-center">
      <div
        class="sidebar-menu flex items-center gap-4 py-2 pl-4"
        routerLinkActive="router-link-active"
        [routerLink]="['Bingo-cards']"
        (click)="closeSidebar()"
      >
        <span class="sidebar-icon flex items-center justify-center">
          <img
            class="w-full h-full"
            src="../../../../assets/Icons/bingo-cards.svg"
          />
        </span>
        <span class="sidebar-text">Bingo Cards</span>
      </div>
    </button>
    <button class="sidebar-btn w-full flex items-center justify-center">
      <div
        class="sidebar-menu flex items-center gap-4 py-2 pl-4"
        routerLinkActive="router-link-active"
        [routerLink]="['User-list']"
        (click)="closeSidebar()"
      >
        <span class="sidebar-icon flex items-center justify-center">
          <img
            class="w-full h-full"
            src="../../../../assets/Icons/user-circle-single.svg"
          />
        </span>
        <span class="sidebar-text">Users List</span>
      </div>
    </button>
    <button class="sidebar-btn w-full flex items-center justify-center">
      <div
        class="sidebar-menu flex items-center gap-4 py-2 pl-4"
        routerLinkActive="router-link-active"
        [routerLink]="['account-delete']"
        (click)="closeSidebar()"
      >
        <span class="sidebar-icon flex items-center justify-center">
          <img
            class="w-full h-full"
            src="../../../../assets/Icons/user-delete-admin.svg"
          />
        </span>
        <span class="sidebar-text">Account Delete</span>
      </div>
    </button>
    <button class="sidebar-btn w-full flex items-center justify-center">
      <div
        class="sidebar-menu flex items-center gap-4 py-2 pl-4"
        routerLinkActive="router-link-active"
        [routerLink]="['notification']"
        (click)="closeSidebar()"
      >
        <span class="sidebar-icon flex items-center justify-center">
          <img
            class="w-full h-full"
            src="../../../../assets/Icons/notification.svg"
          />
        </span>
        <span class="sidebar-text">Notification</span>
      </div>
    </button>
    <button class="sidebar-btn w-full flex items-center justify-center">
      <div
        class="sidebar-menu flex items-center gap-4 py-2 pl-4"
        routerLinkActive="router-link-active"
        [routerLink]="['push-notification']"
        (click)="closeSidebar()"
      >
        <span class="sidebar-icon flex items-center justify-center">
          <img
            class="w-full h-full"
            src="../../../../assets/Icons/notification.svg"
          />
        </span>
        <span class="sidebar-text">Push Notification</span>
      </div>
    </button>
    <button class="sidebar-btn w-full flex items-center justify-center">
      <div
        class="sidebar-menu flex items-center gap-4 py-2 pl-4"
        routerLinkActive="router-link-active"
        [routerLink]="['offline-checkin']"
        (click)="closeSidebar()"
      >
        <span class="sidebar-icon flex items-center justify-center">
          <img
            class="w-full h-full"
            src="../../../../assets/Icons/check-in.svg"
          />
        </span>
        <span class="sidebar-text">Offline Check-in</span>
      </div>
    </button>
  </div>
</div>
